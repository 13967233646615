import { TableContainer, styled } from '@mui/material';
import { baseWhite } from '../../../style/colors';
import { APP_HEADER_HEIGHT } from 'constants/general';

const StyledTableContainer = styled(TableContainer)(
  ({ isDashboard }: { isDashboard?: boolean }) => ({
    height: isDashboard
      ? `calc(100vh - ${APP_HEADER_HEIGHT}px - 77px - 40px)`
      : '100%',
    backgroundColor: baseWhite,
  }),
);

export default StyledTableContainer;
