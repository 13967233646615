import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Container, styled } from '@mui/material';

import { useAppDispatch, useAppSelector } from './app/hooks';

import { getUser } from './features/user_settings/services/userSettingsService';
import {
  selectUserIsLoading,
  setCurrentLocaleId,
} from './features/user_settings/slices/userSettingsSlice';

import CenteredBox from './components/shared/centeredBox';
import Loader from './components/shared/loader';

import { APP_SIDEBAR_WIDTH } from './constants/general';
import { TestId } from './constants/testIds';

import { backgroundGrey } from './style/colors';
import './style/index';

import ErrorPage from './components/error_page/error_page';
import Header from './components/header';
import Sidebar from './components/sidebar';
import SentryHelpers from './helpers/SentryHelpers';

const StyledMainSection = styled(Container)({
  minHeight: '100vh',
  width: `calc(100vw - ${APP_SIDEBAR_WIDTH}px)`,
  margin: '0 0 0 auto',
  backgroundColor: backgroundGrey,

  '@media (min-width: 600px)': {
    padding: '0 39px 26px',
  },
});

function App() {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const isUserLoading = useAppSelector(selectUserIsLoading);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCurrentLocaleId(i18n.language));
  }, [dispatch, i18n.language]);

  if (isUserLoading) {
    return (
      <CenteredBox>
        <Loader />
      </CenteredBox>
    );
  }

  return (
    <Container data-testid={TestId.APP} disableGutters maxWidth={false}>
      <Sidebar />
      <StyledMainSection maxWidth={false}>
        <Header />
        <Outlet />
      </StyledMainSection>
    </Container>
  );
}

export default SentryHelpers.withSentryErrorBoundary(App, {
  fallback: ErrorPage,
});
